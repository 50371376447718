<script>
    export default {
        name: 'PostsSection'
    };
</script>

<template>
    <section class="posts-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="section-heading">
                        we believe in service. <span>sometimes we write about it too</span>
                    </p>
                </div>
            </div>
            <div class="row posts-grid">
                <article class="col-12 col-lg-6 grid-item">
                    <span class="bookmark"></span>
                    <a href="#" class="item-wrapper" title="">
                        <img src="/assets/images/post-grid.png" alt="">
                    </a>
                    <div class="header-wrapper">
                        <header>
                            <p class="post-heading content-heading">
                                <a href="#" title="">
                                    Top 5 tips for providing customer service via
                                </a>
                            </p>
                            <p class="post-desc">
                                Social media is now part of everyday life, and the average person spends 30 per cent of their day using it to voice their views, post pictures of their food, or scroll through the latest news from their friends
                            </p>
                        </header>
                        <a href="#" class="btn btn-link btn-read-more with-arrow" title="">
                            read more
                        </a>
                    </div>
                </article>
                <article class="col-12 col-lg-6 grid-item">
                    <a href="#" class="item-wrapper" title="">
                        <img src="/assets/images/post-grid.png" alt="">
                    </a>
                    <div class="header-wrapper">
                        <header>
                            <p class="post-heading content-heading">
                                <a href="#" title="">
                                    Christmas sales: how to turn a returns handling nightmare into a customer service opportunity
                                </a>
                            </p>
                            <p class="post-desc">
                                Just five years ago, Black Friday was a strictly-US event. Now the global phenomenon is anticipated by bargain hunters the world over and, rather than being a single day in the retail calendar, marks the start of the most crucial time of the year.
                            </p>
                        </header>
                        <a href="#" class="btn btn-link btn-read-more with-arrow" title="">
                            read more
                        </a>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>
