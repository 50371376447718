var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "navbar navbar-expand-lg fixed-top" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "navbar-brand-wrapper" }, [
          _c("a", { attrs: { href: "#", title: "" } }, [
            _c("img", { attrs: { src: "/assets/icons/logo.svg", alt: "" } })
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "navbar-toggler hamburger hamburger--boring",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#navbar-collapse-wrapper",
              "aria-controls": "navbar-collapse-wrapper",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation"
            }
          },
          [
            _c("span", { staticClass: "hamburger-box" }, [
              _c("span", { staticClass: "hamburger-inner" }, [
                _c("i", { staticClass: "fas fa-bars" })
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbar-collapse-wrapper" }
          },
          [
            _c("div", { staticClass: "navbar-misc-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "navbar-pages-wrapper",
                  staticStyle: { "border-right": "0px solid #ffffff" }
                },
                [
                  _c("ul", { staticClass: "navbar-nav navbar-pages" }, [
                    _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "https://www.majorel.com/future-customer/",
                            title: "Blog",
                            "data-gtm": '["Blog and Magazine clicks"]',
                            "data-gtmp": '{"eventAct":"Blog"}'
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Blog\n                    "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-lang-wrapper" }, [
                _c("ul", { staticClass: "navbar-nav navbar-lang" }, [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link lang-item lang-item-2 lang-item-en lang-item-first",
                        attrs: {
                          href: "https://www.majorel.com",
                          title: "English",
                          "data-gtm": '["Choose Language"]',
                          "data-gtmp": '{"eventLab":"English"}'
                        }
                      },
                      [
                        _vm._v(
                          "\n                        en\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link lang-item lang-item-59 lang-item-de",
                        attrs: {
                          href: "https://de.majorel.com",
                          title: "Deutsch",
                          "data-gtm": '["Choose Language"]',
                          "data-gtmp": '{"eventLab":"Deutsch"}'
                        }
                      },
                      [
                        _vm._v(
                          "\n                        de\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link lang-item lang-item-63 lang-item-es",
                        attrs: {
                          href: "https://es.majorel.com",
                          title: "Español",
                          "data-gtm": '["Choose Language"]',
                          "data-gtmp": '{"eventLab":"Español"}'
                        }
                      },
                      [
                        _vm._v(
                          "\n                        es\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link lang-item lang-item-12 lang-item-fr no-translation",
                        attrs: {
                          href: "https://fr.majorel.com",
                          title: "Français",
                          "data-gtm": '["Choose Language"]',
                          "data-gtmp": '{"eventLab":"Français"}'
                        }
                      },
                      [
                        _vm._v(
                          "\n                        fr\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link lang-item lang-item-49 lang-item-pl",
                        attrs: {
                          href: "https://pl.majorel.com",
                          title: "Polski",
                          "data-gtm": '["Choose Language"]',
                          "data-gtmp": '{"eventLab":"Polski"}'
                        }
                      },
                      [
                        _vm._v(
                          "\n                        pl\n                    "
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "navbar-social-wrapper",
                  staticStyle: { display: "none" }
                },
                [
                  _c("ul", { staticClass: "navbar-nav navbar-social" }, [
                    _c("li", { staticClass: "nav-item" }, [
                      _c("a", {
                        staticClass: "nav-link social-twitter",
                        attrs: {
                          target: "_blank",
                          href: "https://twitter.com/majorel_global",
                          title: "Twitter",
                          "data-gtm": '["Social Networks - header"]',
                          "data-gtmp": '{"socialNet":"Twitter"}'
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "nav-item" }, [
                      _c("a", {
                        staticClass: "nav-link social-linkedin",
                        attrs: {
                          target: "_blank",
                          href:
                            "https://www.linkedin.com/company/majorel-global",
                          title: "Linkedin",
                          "data-gtm": '["Social Networks - header"]',
                          "data-gtmp": '{"socialNet":"Linkedin"}'
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "nav-item" }, [
                      _c("a", {
                        staticClass: "nav-link social-youtube",
                        attrs: {
                          target: "_blank",
                          href:
                            "https://www.youtube.com/channel/UCikRjlLEAvgNVhTMrhSoccw?",
                          title: "Youtube",
                          "data-gtm": '["Social Networks - header"]',
                          "data-gtmp": '{"socialNet":"Youtube"}'
                        }
                      })
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }