var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "breadcrumb-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("ol", { staticClass: "breadcrumb " }, [
              _c("li", { staticClass: "breadcrumb-item" }, [
                _c("a", { staticClass: "underline", attrs: { href: "/" } }, [
                  _vm._v(
                    "\n                                Home\n                            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item active",
                  attrs: { "aria-current": "page" }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                                走进Majorel\n                            "
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }