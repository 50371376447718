<script>
    export default {
        name: 'TheNavbar'
    };
</script>

<template>
    <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container">
            <div class="navbar-brand-wrapper">
                <a href="#" title="">
                    <img src="/assets/icons/logo.svg" alt="">
                </a>
            </div>
            <button class="navbar-toggler hamburger hamburger--boring" type="button" data-toggle="collapse"
                data-target="#navbar-collapse-wrapper" aria-controls="navbar-collapse-wrapper" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="hamburger-box">
                    <span class="hamburger-inner">
                        <i class="fas fa-bars"></i>
                    </span>
                </span>
        </button>
            <div id="navbar-collapse-wrapper" class="collapse navbar-collapse">
               
                <div class="navbar-misc-wrapper">
        <div class="navbar-pages-wrapper" style="border-right:0px solid #ffffff;">
            <ul class="navbar-nav navbar-pages">
                <li class="nav-item">
                    <a href="https://www.majorel.com/future-customer/" class="nav-link" title="Blog" data-gtm="[&quot;Blog and Magazine clicks&quot;]" data-gtmp="{&quot;eventAct&quot;:&quot;Blog&quot;}">
                            Blog
                        </a>
                </li>
            </ul>
        </div>
        <div class="navbar-lang-wrapper">
            <ul class="navbar-nav navbar-lang">
                <li class="nav-item">
                    <a href="https://www.majorel.com" title="English" class="nav-link lang-item lang-item-2 lang-item-en lang-item-first" data-gtm="[&quot;Choose Language&quot;]" data-gtmp="{&quot;eventLab&quot;:&quot;English&quot;}">
                            en
                        </a>
                </li>
                <li class="nav-item">
                    <a href="https://de.majorel.com" title="Deutsch" class="nav-link lang-item lang-item-59 lang-item-de" data-gtm="[&quot;Choose Language&quot;]" data-gtmp="{&quot;eventLab&quot;:&quot;Deutsch&quot;}">
                            de
                        </a>
                </li>
                <li class="nav-item">
                    <a href="https://es.majorel.com" title="Español" class="nav-link lang-item lang-item-63 lang-item-es" data-gtm="[&quot;Choose Language&quot;]" data-gtmp="{&quot;eventLab&quot;:&quot;Español&quot;}">
                            es
                        </a>
                </li>
                <li class="nav-item">
                    <a href="https://fr.majorel.com" title="Français" class="nav-link lang-item lang-item-12 lang-item-fr no-translation" data-gtm="[&quot;Choose Language&quot;]" data-gtmp="{&quot;eventLab&quot;:&quot;Français&quot;}">
                            fr
                        </a>
                </li>
                <li class="nav-item">
                    <a href="https://pl.majorel.com" title="Polski" class="nav-link lang-item lang-item-49 lang-item-pl" data-gtm="[&quot;Choose Language&quot;]" data-gtmp="{&quot;eventLab&quot;:&quot;Polski&quot;}">
                            pl
                        </a>
                </li>
            </ul>
        </div>
        <div class="navbar-social-wrapper" style="display:none;">
            <ul class="navbar-nav navbar-social">
                <li class="nav-item">
                    <a target="_blank" href="https://twitter.com/majorel_global" class="nav-link social-twitter" title="Twitter" data-gtm="[&quot;Social Networks - header&quot;]" data-gtmp="{&quot;socialNet&quot;:&quot;Twitter&quot;}"></a>
                </li>
                <li class="nav-item">
                    <a target="_blank" href="https://www.linkedin.com/company/majorel-global" class="nav-link social-linkedin" title="Linkedin" data-gtm="[&quot;Social Networks - header&quot;]" data-gtmp="{&quot;socialNet&quot;:&quot;Linkedin&quot;}"></a>
                </li>
                <li class="nav-item">
                    <a target="_blank" href="https://www.youtube.com/channel/UCikRjlLEAvgNVhTMrhSoccw?" class="nav-link social-youtube" title="Youtube" data-gtm="[&quot;Social Networks - header&quot;]" data-gtmp="{&quot;socialNet&quot;:&quot;Youtube&quot;}"></a>
                </li>
            </ul>
        </div>
    </div>
            </div>
   
        </div>
    </nav>
</template>
