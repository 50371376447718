const _video = '#banner-video';
const _parent = '.banner-section';
const _overlay = '.video-overlay';

function adjustVideo() {
    const video = $(_video);

    const video_w = video.data('origin-width');
    const video_h = video.data('origin-height');

    const parent = video.parents(_parent);

    const container_w = parent.width();
    const container_h = parent.height();

    const scale_w = container_w / video_w;
    const scale_h = container_h / video_h;

    const scale = scale_w > scale_h ? scale_w : scale_h;

    video.width(scale * video_w);
    video.height(scale * video_h);
}

function fadeOutOverlay() {
    const overlay = $(_parent).find(_overlay);

    overlay.css('opacity', 0);
}

export function init() {
    window.addEventListener('load', adjustVideo);
    window.addEventListener('resize', adjustVideo);

    $(_video).on('canplay', fadeOutOverlay);
}
