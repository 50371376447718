var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "globe-section" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "section-heading" }, [
              _vm._v(
                "\n                    我们无处不在\n                    "
              ),
              _c("span", [_vm._v("诚邀您的来访")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("img", {
              staticClass: "globe-image",
              attrs: { src: "/assets/images/globe.png", alt: "" }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }