var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "news-section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "section-heading" }, [
              _vm._v(
                "\n                    we believe in innovation.\n                    "
              ),
              _c("span", [_vm._v("here’s the latest")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "news-wrapper" }, [
          _c("span", { staticClass: "bookmark" }),
          _vm._v(" "),
          _c("div", { staticClass: "row news-grid" }, [
            _c("div", { staticClass: "col-12 col-lg-6" }, [
              _c("p", { staticClass: "section-heading" }, [
                _vm._v("\n                        what's "),
                _c("span", [_vm._v("new")])
              ]),
              _vm._v(" "),
              _c("article", { staticClass: "grid-item" }, [
                _c("div", { staticClass: "date-wrapper" }, [
                  _c("span", [_vm._v("january 7, 2019")])
                ]),
                _vm._v(" "),
                _c("header", [
                  _c("p", { staticClass: "news-heading content-heading" }, [
                    _c("a", { attrs: { href: "#", title: "" } }, [
                      _vm._v(
                        "\n                                    Bertelsmann and Saham Complete Combination of Worldwide CRM Businesses\n                                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "naws-desc" }, [
                    _vm._v(
                      "\n                                Bertelsmann, the international media, services, and education company, and Morocco’s Saham Group have combined their worldwide\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-link btn-read-more with-arrow",
                    attrs: { href: "#", title: "" }
                  },
                  [
                    _vm._v(
                      "\n                            read more\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("article", { staticClass: "grid-item" }, [
                _c("div", { staticClass: "date-wrapper" }, [
                  _c("span", [_vm._v("september 18, 2018")])
                ]),
                _vm._v(" "),
                _c("header", [
                  _c("p", { staticClass: "news-heading content-heading" }, [
                    _c("a", { attrs: { href: "#", title: "" } }, [
                      _vm._v(
                        "\n                                    Thomas Mackenbrock Appointed as New CEO of Arvato CRM Solutions\n                                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "naws-desc" }, [
                    _vm._v(
                      "\n                                Thomas Rabe, Chairman & CEO of Bertelsmann and Chariman of the Arvato Board, said: “In Thomas Mackenbrock, an internationally\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-link btn-read-more with-arrow",
                    attrs: { href: "#", title: "" }
                  },
                  [
                    _vm._v(
                      "\n                            read more\n                        "
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-6" })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }