import * as NAVBAR from './navbar';
import * as BANNER_VIDEO from './banner-video';
import * as STATISTICS_ANIM from './statistics-anim';
import * as OWL_OPINIONS from './owl.opinions';
import * as OWL_LOGOTYPES from './owl.logotypes';
import * as CONTACT_FORM from './contact-form';
import * as AUTOFOCUS from './autofocus';

(function () {
    'use strict';

    $(document).ready(() => {
        NAVBAR.init();

        BANNER_VIDEO.init();

        STATISTICS_ANIM.init();

        OWL_OPINIONS.init();

        OWL_LOGOTYPES.init();

        CONTACT_FORM.init();

        AUTOFOCUS.init();
    });

})();
