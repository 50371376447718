<script>
    export default {
        name: 'BreadcrumbSection'
    };
</script>

<template>
    <div class="breadcrumb-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12">
                 <ol class="breadcrumb ">
                            <li class="breadcrumb-item">
                                <a href="/" class="underline">
                                    Home
                                </a>
                            </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                <span>
                                    走进Majorel
                                </span>
                            </li>
                  </ol>
                </div>
            </div>
        </div>
    </div>
</template>
