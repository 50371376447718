const _selector = '.navbar';
const _toggler = '.navbar-toggler';
const _navbarCollapse = '.navbar-collapse';
const _linkCollapse = '.nav-link-collapse';
const _navbarNavCollapse = '.navbar-nav-collapse';

function registerEvents(navbar) {
    navbar.on('click', _linkCollapse, (e) => {
        if (screen.width <= 991) {
            $(e.currentTarget).parent().find(_navbarNavCollapse).collapse('toggle');
        }
    });

    navbar.on('show.bs.collapse', _navbarCollapse, () => {
        navbar.find(_toggler).addClass('is-active');
    });

    navbar.on('hidden.bs.collapse', _navbarCollapse, () => {
        navbar.find(_toggler).removeClass('is-active');
    });
}

export function init() {
    const navbar = $(_selector);

    if (navbar.length > 0) {
        registerEvents(navbar);
    }
}
