<script>
    export default {
        name: 'GetInTouchSection'
    };
</script>

<template>
    <section class="get-in-touch-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="section-heading">
                        we speak your language. <span>and we’d love to hear from you</span>
                    </p>
                </div>
                <div class="col-auto mx-auto">
                    <a href="#" class="btn btn-link with-arrow" title="">
                        please get in touch
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
