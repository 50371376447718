import Vue from 'vue';

import TheNavbar from '../templates/TheNavbar';
import BreadcrumbSection from '../templates/BreadcrumbSection';
import PostsSection from '../templates/PostsSection';
import NewsSection from '../templates/NewsSection';
import GlobeSection from '../templates/GlobeSection';
import QuestionsSection from '../templates/QuestionsSection';
import GetInTouchSection from '../templates/GetInTouchSection';
import NewsletterSection from '../templates/NewsletterSection';

const app = new Vue({
    el: '#app',
    components: {
        TheNavbar,
        BreadcrumbSection,
        PostsSection,
        NewsSection,
        GlobeSection,
        QuestionsSection,
        GetInTouchSection,
        NewsletterSection
    }
});
