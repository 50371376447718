<script>
    export default {
        name: 'QuestionsSection'
    };
</script>

<template>
    <section class="questions-section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-auto">
                    <div class="question-wrapper">
                        <p class="question-heading section-heading"><span>we’re driven to</span> make the complex simple</p>
                        <p class="question-desc">
                            <strong>4</strong> QUESTIONS, <strong>1</strong> MINUTE
                            <small>to get a solution for your challenge</small>
                        </p>
                        <a href="#" class="btn btn-link btn-start with-arrow" title="">
                            start
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
