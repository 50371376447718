const _selector = '.opinions-carousel';

const options = {
    loop: true,
    dots: false,
    nav: true,
    autoplay: false,
    navText: [
        $('<img src="/assets/icons/owl-arrow-left.svg" alt="prev">'),
        $('<img src="/assets/icons/owl-arrow-right.svg" alt="next">')
    ],
    responsiveClass: true,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 1
        },
        992: {
            items: 1
        },
        1200: {
            items: 3
        }
    }
};

export function init() {
    let carousel = $(_selector);

    if (carousel.length > 0) {
        carousel.owlCarousel(options);
    }
}
