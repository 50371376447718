const _gridSelector = '.statistics-grid';
const _pending = '.pending';
const _numSelector = '.num';

function eventCallback() {
    const scrollY = $(window).scrollTop();

    const selector = `${_gridSelector}${_pending}`;

    $(selector).each(function () {
        const grid = $(this);

        if (scrollY + window.innerHeight > grid.offset().top) {
            triggerAnimation(grid);
        }
    });
}

function triggerAnimation(grid) {
    grid.removeClass(_pending);

    grid.find(_numSelector).each(function () {
        $(this).animate({value: $(this).data('value')}, {
                duration: 2500,
                easing: 'swing',
                step: function (now) {
                    const value = Math.ceil(now);

                    if (value > 1000) {
                        const array = [...value.toString()];

                        array.splice(array.length - 3, 0, ',').join();

                        $(this).children('span').html(array);
                    } else {
                        $(this).children('span').html(value);
                    }
                }
            }
        );
    });
}

export function init() {
    window.addEventListener('scroll', eventCallback);
    window.addEventListener('load', eventCallback);
}
