<script>
    export default {
        name: 'NewsSection'
    };
</script>

<template>
    <section class="news-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="section-heading">
                        we believe in innovation.
                        <span>here’s the latest</span>
                    </p>
                </div>
            </div>
            <div class="news-wrapper">
                <span class="bookmark"></span>
                <div class="row news-grid">
                    <div class="col-12 col-lg-6">
                        <p class="section-heading">
                            what's <span>new</span>
                        </p>
                        <article class="grid-item">
                            <div class="date-wrapper">
                                <span>january 7, 2019</span>
                            </div>
                            <header>
                                <p class="news-heading content-heading">
                                    <a href="#" title="">
                                        Bertelsmann and Saham Complete Combination of Worldwide CRM Businesses
                                    </a>
                                </p>
                                <p class="naws-desc">
                                    Bertelsmann, the international media, services, and education company, and Morocco’s Saham Group have combined their worldwide
                                </p>
                            </header>
                            <a href="#" class="btn btn-link btn-read-more with-arrow" title="">
                                read more
                            </a>
                        </article>
                        <article class="grid-item">
                            <div class="date-wrapper">
                                <span>september 18, 2018</span>
                            </div>
                            <header>
                                <p class="news-heading content-heading">
                                    <a href="#" title="">
                                        Thomas Mackenbrock Appointed as New CEO of Arvato CRM Solutions
                                    </a>
                                </p>
                                <p class="naws-desc">
                                    Thomas Rabe, Chairman & CEO of Bertelsmann and Chariman of the Arvato Board, said: “In Thomas Mackenbrock, an internationally
                                </p>
                            </header>
                            <a href="#" class="btn btn-link btn-read-more with-arrow" title="">
                                read more
                            </a>
                        </article>
                    </div>
                    <div class="col-12 col-lg-6"></div>
                </div>
            </div>
        </div>
    </section>
</template>
