var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "posts-section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "section-heading" }, [
              _vm._v("\n                    we believe in service. "),
              _c("span", [_vm._v("sometimes we write about it too")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row posts-grid" }, [
          _c("article", { staticClass: "col-12 col-lg-6 grid-item" }, [
            _c("span", { staticClass: "bookmark" }),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "item-wrapper", attrs: { href: "#", title: "" } },
              [
                _c("img", {
                  attrs: { src: "/assets/images/post-grid.png", alt: "" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "header-wrapper" }, [
              _c("header", [
                _c("p", { staticClass: "post-heading content-heading" }, [
                  _c("a", { attrs: { href: "#", title: "" } }, [
                    _vm._v(
                      "\n                                Top 5 tips for providing customer service via\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "post-desc" }, [
                  _vm._v(
                    "\n                            Social media is now part of everyday life, and the average person spends 30 per cent of their day using it to voice their views, post pictures of their food, or scroll through the latest news from their friends\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-link btn-read-more with-arrow",
                  attrs: { href: "#", title: "" }
                },
                [
                  _vm._v(
                    "\n                        read more\n                    "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "col-12 col-lg-6 grid-item" }, [
            _c(
              "a",
              { staticClass: "item-wrapper", attrs: { href: "#", title: "" } },
              [
                _c("img", {
                  attrs: { src: "/assets/images/post-grid.png", alt: "" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "header-wrapper" }, [
              _c("header", [
                _c("p", { staticClass: "post-heading content-heading" }, [
                  _c("a", { attrs: { href: "#", title: "" } }, [
                    _vm._v(
                      "\n                                Christmas sales: how to turn a returns handling nightmare into a customer service opportunity\n                            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "post-desc" }, [
                  _vm._v(
                    "\n                            Just five years ago, Black Friday was a strictly-US event. Now the global phenomenon is anticipated by bargain hunters the world over and, rather than being a single day in the retail calendar, marks the start of the most crucial time of the year.\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-link btn-read-more with-arrow",
                  attrs: { href: "#", title: "" }
                },
                [
                  _vm._v(
                    "\n                        read more\n                    "
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }