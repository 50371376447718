<script>
    export default {
        name: 'NewsletterSection'
    };
</script>

<template>
    <section class="newsletter-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="section-heading">
                   
                    </p>
                </div>
                <div class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 form-wrapper">
                  
                </div>
                <div class="col-12 socials-wrapper">
                    <ul class="nav">
                        <li class="nav-item">
                            <a target="_blank" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNTcyNjMxNQ==&scene=124#wechat_redirect" class="nav-link" style="width:40px;"><img src="/assets/icons/social_icons/wechat2.svg" style="max-height:40px;"/></a>
                        </li>
                         <li class="nav-item">
                            <a target="_blank" href="https://weibo.com/arvatocrm?is_all=1" class="nav-link" style="width:40px;"><img src="/assets/icons/social_icons/weibo2.svg" style="max-height:40px;"/></a>
                        </li>
                          <li class="nav-item">
                            <a target="_blank" href="https://v.douyin.com/eNAByEa/" class="nav-link" style="width:40px;"><img src="/assets/icons/social_icons/TikTok2.svg" style="max-height:40px;"/></a>
                        </li>
                          <li class="nav-item">
                            <a target="_blank" href="https://space.bilibili.com/592131117" class="nav-link" style="width:90px;"><img src="/assets/icons/social_icons/BILIBILI2.svg" style="max-height:40px;"/></a>
                        </li>
                    </ul>
                </div>
                  <div class="col-12 pages-wrapper">
                    <ul class="nav">
                        <li class="nav-item">
                            <a href="mailto:info@arvato.cn" class="nav-link" title="">
                                联系我们
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="https://www.majorel.com/" target="_blank" class="nav-link" title="">
                                网站地图
                            </a>
                        </li>
                        <li class="nav-item legal-notice" target="_blank">
                            <a href="https://www.majorel.com/" class="nav-link" title="">
                                网站声明
                            </a>
                        </li>
                        <li class="nav-item privacy-policy">
                            <a href="https://www.majorel.com/privacy-policy/" target="_blank" class="nav-link" title="">
                                隐私条款
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 text-center mt-5" style="color:#ffffff;">
                    Copyright © 2020-2021 Majorel All Rights Reserved
                </div>
            </div>
        </div>
    </section>
</template>
