var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "get-in-touch-section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "section-heading" }, [
              _vm._v("\n                    we speak your language. "),
              _c("span", [_vm._v("and we’d love to hear from you")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto mx-auto" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-link with-arrow",
                attrs: { href: "#", title: "" }
              },
              [
                _vm._v(
                  "\n                    please get in touch\n                "
                )
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }