<script>
    export default {
        name: 'GlobeSection'
    };
</script>

<template>
    <section class="globe-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <p class="section-heading">
                        我们无处不在
                        <span>诚邀您的来访</span>
                    </p>
                </div>
                <div class="col-12">
                    <img src="/assets/images/globe.png" alt="" class="globe-image">
                </div>
            </div>
        </div>
    </section>
</template>
