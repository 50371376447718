var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "newsletter-section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "section-heading" })
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 form-wrapper"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 socials-wrapper" }, [
            _c("ul", { staticClass: "nav" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { width: "40px" },
                    attrs: {
                      target: "_blank",
                      href:
                        "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNTcyNjMxNQ==&scene=124#wechat_redirect"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { "max-height": "40px" },
                      attrs: { src: "/assets/icons/social_icons/wechat2.svg" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { width: "40px" },
                    attrs: {
                      target: "_blank",
                      href: "https://weibo.com/arvatocrm?is_all=1"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { "max-height": "40px" },
                      attrs: { src: "/assets/icons/social_icons/weibo2.svg" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { width: "40px" },
                    attrs: {
                      target: "_blank",
                      href: "https://v.douyin.com/eNAByEa/"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { "max-height": "40px" },
                      attrs: { src: "/assets/icons/social_icons/TikTok2.svg" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { width: "90px" },
                    attrs: {
                      target: "_blank",
                      href: "https://space.bilibili.com/592131117"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { "max-height": "40px" },
                      attrs: { src: "/assets/icons/social_icons/BILIBILI2.svg" }
                    })
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 pages-wrapper" }, [
            _c("ul", { staticClass: "nav" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { href: "mailto:info@arvato.cn", title: "" }
                  },
                  [
                    _vm._v(
                      "\n                            联系我们\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: "https://www.majorel.com/",
                      target: "_blank",
                      title: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                            网站地图\n                        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item legal-notice",
                  attrs: { target: "_blank" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: { href: "https://www.majorel.com/", title: "" }
                    },
                    [
                      _vm._v(
                        "\n                            网站声明\n                        "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item privacy-policy" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: "https://www.majorel.com/privacy-policy/",
                      target: "_blank",
                      title: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                            隐私条款\n                        "
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-12 text-center mt-5",
              staticStyle: { color: "#ffffff" }
            },
            [
              _vm._v(
                "\n                Copyright © 2020-2021 Majorel All Rights Reserved\n            "
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }