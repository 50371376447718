const _form = '.contact-form';
const _browseFile = '.browse-file';

function registerEvents(form) {
    form.on('focusin', 'input.form-control', (e) => {
        $(e.currentTarget).parent().addClass('focused');
    });

    form.on('focusout', 'input.form-control', (e) => {
        $(e.currentTarget).parent().removeClass('focused');
    });

    form.on('input', '.form-control', (e) => {
        if (e.currentTarget.value.length > 0) {
            $(e.currentTarget).parent().addClass('filled');
        } else {
            $(e.currentTarget).parent().removeClass('filled');
        }
    });

    form.on('click', _browseFile, (e) => {
        $(e.currentTarget).parent().find('[type=file]').click();
    });
}

export function init() {
    const form = $(_form);

    if (form.length > 0) {
        registerEvents(form);
    }
}
