var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "questions-section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-lg-auto" }, [
            _c("div", { staticClass: "question-wrapper" }, [
              _c("p", { staticClass: "question-heading section-heading" }, [
                _c("span", [_vm._v("we’re driven to")]),
                _vm._v(" make the complex simple")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "question-desc" }, [
                _c("strong", [_vm._v("4")]),
                _vm._v(" QUESTIONS, "),
                _c("strong", [_vm._v("1")]),
                _vm._v(" MINUTE\n                        "),
                _c("small", [_vm._v("to get a solution for your challenge")])
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-link btn-start with-arrow",
                  attrs: { href: "#", title: "" }
                },
                [
                  _vm._v(
                    "\n                        start\n                    "
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }