const _selector = '[autofocus]';

function registerEvents(elements) {
    elements.first().focus();
}

export function init() {
    const elements = $(_selector);

    if (elements.length > 0) {
        registerEvents(elements);
    }
}
